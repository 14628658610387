@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@500&display=swap');
@import '~stream-chat-react/dist/css/v2/index.css';

body {
  overflow: hidden;
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  height: 100%;
  background-color: white;
  ::-webkit-scrollbar {
    display: none;
  }
}

html {
  font-family: 'Poppins', sans-serif;
  font-weight: 400;
  height: 100%;
}

.ReactModal__Overlay {
  opacity: 1;
}

.ReactModal__Overlay--after-open {
  opacity: 1;
}

.ReactModal__Overlay--before-close {
  opacity: 1;
}

.str-chat {
  --str-chat__font-family: 'Poppins', sans-serif;
}